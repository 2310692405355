
import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import {OdemeTipiEnum} from "@/plugins/uyap-plugin/takip_ac/OdemeTipleri";
import {TamamlanmayanDosya} from "@/components/forms/form-wizard/eicrapro/TakipAcFormWizard.vue";
import {VakifbankHesapBilgileri} from "@/plugins/uyap-plugin/uyap/VakifbankHesapListesi";

@Component({
  components: {Tutar, FormWrapper}
})
export default class TakipAcTebligatGideriForm extends Mixins(SimpleInputMixin) {
  @Prop() toplu!: boolean | string;
  @Prop() dosya!: TamamlanmayanDosya | Array<TamamlanmayanDosya>;

  odemeTipi = OdemeTipiEnum;
  localValue!: {
    odemeTipi: OdemeTipiEnum,
    vakifbankHesapBilgileri: VakifbankHesapBilgileri
    tebligatGideri: {
      tutar: string,
      aciklama: string,
      enabled: boolean
    }
  };
  itemsLoaded = false;
  vakifbankItems: Array<VakifbankHesapBilgileri> = [];
  headers = [
    {text: 'Şube Adı', value: 'subeAdi'},
    {text: 'Şube Kodu', value: 'subeKodu'},
    {text: 'Hesap No', value: 'hesapKartNo'},
    {text: 'Hesap Tipi', value: 'hesapTipi'},
    {text: 'Bakiye Durumu', value: 'bakiyeDurumu'}
  ]

  get isToplu() {
    return this.toplu || this.toplu === "";
  }

  async odemeTipiInput() {
    if (this.localValue.odemeTipi == this.odemeTipi.Vakifbank) {
      this.itemsLoaded = false;
      let tutar = 0;
      if (this.isToplu && Array.isArray(this.dosya)) {
        for (let tamamlanmayanDosya of this.dosya) {
          if (tamamlanmayanDosya.createByEicrapro) {
            tutar += tamamlanmayanDosya.eicraproData.dosya.harcMasrafToplam;
          } else {
            let res = await this.$uyap.DosyaHarcMasrafHesabi().run({
              dosyaId: tamamlanmayanDosya.dosyaID,
              dosyaTurKod: 35
            });
            tutar += res.toplam;
          }
        }
      } else if (!Array.isArray(this.dosya)) {
        if (this.dosya.createByEicrapro) {
          tutar = this.dosya.eicraproData.dosya.harcMasrafToplam;
        } else {
          let res = await this.$uyap.DosyaHarcMasrafHesabi().run({dosyaId: this.dosya.dosyaID, dosyaTurKod: 35});
          tutar = res.toplam;
        }
      }
      let vakifbankHesapListesi = await this.$uyap.VakifbankHesapListesi().run({tutar: tutar, uyapGrupId: ""});
      this.vakifbankItems = vakifbankHesapListesi.hesapBilgileri.HesapBilgileriDVO;
      this.vakifbankItems.forEach(item => {
        // @ts-ignore
        item.tempId = item.subeKodu + item.hesapKartNo;
      });
      this.itemsLoaded = true;
    }
    this.input();
  }
}
